@tailwind base;
@tailwind components;
@tailwind utilities;

/* Light Mode Styles */
body {
  @apply bg-lightBg text-lightText;
  padding-left: 10%;
  padding-right: 10%;
}

/* Dark Mode Styles */
.dark body {
  @apply bg-darkBg text-darkText;
  padding-left: 10%;
  padding-right: 10%;
}

/* Mobile Styles */
@media (max-width: 640px) {
  body {
    @apply px-0; /* Removes padding on mobile devices */
  }
}

/* Dark Mode Mobile Styles */
.dark @media (max-width: 640px) {
  body {
    @apply px-0; /* Removes padding on mobile devices */
  }
}
